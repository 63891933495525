import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "./ui/sheet";
import { Link } from "@remix-run/react";
import { User } from "@supabase/supabase-js";
import {
  CircleUser,
  LogOut,
  Menu,
  Package2,
  Search,
  UserCircle,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { cn } from "~/libs/utils";

type Props = {
  className?: string;
  user: User | null;
};
export const Header = ({ className, user }: Props) => {
  return (
    <div
      className={cn(
        "flex gap-x-6 justify-between items-center px-3 py-3 max-w-6xl mx-auto",
        className
      )}
    >
      <header className="flex w-full h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link
            prefetch="intent"
            to="/"
            className="flex items-center gap-2 text-lg font-semibold md:text-base"
          >
            <img
              src="/logo_app.webp"
              alt="Osia Novel Logo"
              className="w-9 h-9 rounded"
            />
            <h2 className="flex w-40 text-lg text-fore-primary font-semibold uppercase">
              Osia Novel
            </h2>
          </Link>
          <Link
            prefetch="intent"
            className="hover:underline underline-offset-2"
            to="/"
          >
            Home
          </Link>
          <Link
            prefetch="intent"
            className="hover:underline underline-offset-2"
            to="/search"
          >
            Search
          </Link>
          <Link
            prefetch="intent"
            className="hover:underline underline-offset-2"
            to="/bookmarks"
          >
            Bookmarks
          </Link>
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <SheetTrigger asChild className="text-left">
                <Link
                  prefetch="viewport"
                  to="/"
                  className="flex items-center gap-2 text-lg font-semibold md:text-base"
                >
                  <img
                    src="/logo_app.webp"
                    alt="Osia Novel Logo"
                    className="w-9 h-9 rounded"
                  />
                  <h2 className="text-xl text-fore-primary font-semibold uppercase">
                    Osia Novel
                  </h2>
                </Link>
              </SheetTrigger>
              <SheetTrigger asChild className="text-left">
                <Link
                  prefetch="viewport"
                  className="hover:underline underline-offset-2"
                  to="/"
                >
                  Home
                </Link>
              </SheetTrigger>
              <SheetTrigger asChild className="text-left">
                <Link
                  prefetch="viewport"
                  className="hover:underline underline-offset-2"
                  to="/search"
                >
                  Search
                </Link>
              </SheetTrigger>
              <SheetTrigger asChild className="text-left">
                <Link
                  prefetch="viewport"
                  className="hover:underline underline-offset-2"
                  to="/bookmarks"
                >
                  Bookmarks
                </Link>
              </SheetTrigger>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex w-full md:hidden justify-center">
          <img
            src="/logo_app.webp"
            alt="Osia Novel Logo"
            className="w-9 h-9 rounded"
          />
        </div>
        <div className="flex md:w-full justify-end items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <div>
            {user ? (
              <DropdownAccount user={user} />
            ) : (
              <Link to={"/login"}>
                <Button>Login</Button>
              </Link>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

const DropdownAccount = ({ user }: { user: User }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex shrink-0 items-center gap-x-2 px-6 py-2 rounded-lg shadow  bg-gray-100 hover:bg-gray-200">
        <div  className="">
          <span className="sr-only">Toggle user menu</span>
          <UserCircle className="h-5 w-5" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          <div>{user?.user_metadata?.username}</div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <Link to={"/logout"}>
          <DropdownMenuItem className="cursor-pointer">
            <LogOut className="h-4 w-4 mr-1" />
            Logout
          </DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
