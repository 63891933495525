import stylesheet from "../index.css?url";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { createServerSupabase } from "./libs/supabase";
import {
  json,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

export const meta: MetaFunction<typeof loader> = () => {
  return [
    {
      title: "Osia Novel - Novel Bookmarks",
    },
    {
      name: "description",
      content: "Osia - The Database of webnovel, chinese novel, korean novel",
    },
  ];
};
export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export function ErrorBoundary() {
  console.log("error");

  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body className="flex flex-1 w-full justify-center items-center h-[50vh]">
        <h1 className="text-2xl">
          An error occurred. Please try again later.
          {/* {isRouteErrorResponse(error)
            ? `${error.status} ${error.statusText}`
            : error instanceof Error
            ? error.message
            : "Unknown Error"} */}
        </h1>
        <Scripts />
      </body>
    </html>
  );
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const [headers, supabase] = createServerSupabase(request);

  const {
    data: { user },
  } = await supabase.auth.getUser();
  // const url = new URL(request.url);
  // const host = request.headers.get("host");
  // if (host && host === "osianovel.com") {
  //   return redirect(`https://www.osianovel.com${url.pathname}`);
  // }

  return json(
    { user },
    {
      headers,
    }
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useLoaderData();
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/png" href="/favicon.ico" />
        <meta
          name="keywords"
          content="ln, light novel, webnovel, chinese novel, korean novel"
        />
        <Meta />
        <Links />
      </head>
      <body className=" pattern-background w-full min-h-screen bg-black">
        {process.env.NODE_ENV === "development" ? null : (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-BDBX3RZ8X2"
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-BDBX3RZ8X2');
                `,
              }}
            />
          </>
        )}
        <Header user={loaderData?.user} />
        {children}
        <Footer />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
