import { DailyPick } from "./components/daily.pick";
import { LastAdded } from "./components/last-added";
import { LastUpdated } from "./components/last-updated";
import { BooksRecommandation } from "./components/recommandation";
import { data, defer, json, LoaderFunction, MetaFunction } from "@remix-run/node";
import { Await, Form, useActionData, useLoaderData } from "@remix-run/react";
import { isbot } from "isbot";
import { Suspense } from "react";
import { Header } from "~/components/header";
import { createServerSupabase } from "~/libs/supabase";

export const meta: MetaFunction = () => {
  return [
    { title: "Osia Novel - Novel Bookmarks" },
    {
      name: "description",
      content:
        "Your Novel Database - Find webnovel, chinese novel, korean novel and more",
    },
  ];
};

export const loader: LoaderFunction = async ({ request }) => {
  const perf = new Date();
  const [headers, supabase] = createServerSupabase(request);
  const isBot = isbot(request.headers.get("user-agent") || "");
  if (isBot) {
    console.log("isBot =>", isBot);
  }

  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const getBooksRecommandation = async () => {
    const { data: booksRecommandation } = await supabase
      .from("ref_books")
      .select("*")
      .eq("others->>recommandation", true)
      .limit(30);

    return booksRecommandation;
  };

  const getBooksUpdated = async () => {
    const perf = new Date();
    const { data: booksUpdated, error } = await supabase
      .from("ref_books")
      .select("*")
      .not("last_updated", "is", null)
      .order("last_updated", { ascending: false })
      .limit(8);

    console.log("Time to load Book", new Date().getTime() - perf.getTime());
    return booksUpdated;
  };

  const getBooksAdded = async () => {
    const { data: booksAdded } = await supabase
      .from("ref_books")
      .select("*")
      .limit(30)
      .order("created_at", { ascending: false });

    return booksAdded;
  };

  const [booksUpdated, booksAdded, booksRecommandation] = await Promise.all([
    getBooksUpdated(),
    getBooksAdded(),
    getBooksRecommandation(),
  ]);

  console.log("Time to load HOME", new Date().getTime() - perf.getTime());
  return data({ booksAdded, booksUpdated, booksRecommandation }, { headers });
};

// :,'!@#$%^&*()_+{}|:"<>?[]\;',.

export default function Home() {
  const { booksAdded, booksUpdated, booksRecommandation } = useLoaderData();
  return (
    <div className="mx-auto max-w-6xl px-3 ">
      <DailyPick />
      <BooksRecommandation books={booksRecommandation} />
      <LastUpdated books={booksUpdated} />
      <LastAdded books={booksAdded} />
    </div>
  );
}
